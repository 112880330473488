/* Import the custom font */
@font-face {
  font-family: 'CustomFont';
  src: url('./assets/fonts/NotoKufiArabic-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

/* Apply the custom font to the entire app */
body, .App, .time-picker-container, .result {
  font-family: 'CustomFont', sans-serif;
}

.App {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f0f0; /* Light background color */
}

.time-picker-container {
  text-align: center;
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.time-picker-container .MuiTextField-root {
  width: 300px; /* Increase the width of the time picker */
  font-size: 1.2rem;
}

.result {
  margin-top: 20px;
  font-size: 1.1rem;
  text-align: right; /* Align text to the right */
  direction: rtl; /* Ensure text direction is RTL */
  font-weight: bold; /* Make Arabic text bold */
}
